import { useQueryClient } from "@tanstack/react-query";
import { format, parseISO } from "date-fns";
import { Table } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import axiosAPI from "../../axiosAPI";
import { useClients } from "../../hooks/useClients";

export default function ClientsTable({ filter, setEditClient, deleteClient }) {
  const { clients, isLoading, isFetching } = useClients(undefined, filter);
  const queryClient = useQueryClient();

  function deleteClient(clientId) {
    axiosAPI
      .delete("/clients/" + clientId)
      .then((response) => {
        queryClient.invalidateQueries({ queryKey: ["clients", "list"] });
        toast.error("Cliente deletado.", { position: "bottom-right" });
      })
      .catch((_) => toast.error("Ops, ocorreu um erro. Tente novamente."));;
  }

  return (
    <>
      {isFetching ? (
        <div className="d-flex justify-content-center m-5">
          <Spinner animation="border" variant="warning" />
        </div>
      ) : (
        <Table
          striped
          bordered
          hover
          responsive
          size="sm"
          style={{
            display: "block",
            height: 400,
            overflow: "auto",
            fontSize: "16px",
          }}
        >
          <thead>
            <tr
              style={{
                position: "sticky",
                top: "0",
                backgroundColor: "#edebe9",
              }}
            >
              <th>Nome</th>
              <th>CPF/CNPJ</th>
              <th>Advogado</th>
              <th>Data Entrada</th>
              <th>Email</th>
              <th>Endereço</th>
              <th>Cidade</th>
              <th>Estado</th>
              <th>Telefone Fixo</th>
              <th>Telefone celular</th>
              <th
                style={{
                  position: "sticky",
                  top: "0",
                  backgroundColor: "#edebe9",
                }}
              >
                Editar
              </th>
              <th
                style={{
                  position: "sticky",
                  top: "0",
                  backgroundColor: "#edebe9",
                }}
              >
                Deletar
              </th>
            </tr>
          </thead>
          <tbody>
            {clients?.map((client) => (
              <tr>
                <td>{client.name}</td>
                <td>{client.cpf}</td>
                <td>{client.lawyer_name}</td>
                <td>{format(parseISO(client.created_at), "dd/MM/yyyy")}</td>
                <td>{client.email}</td>
                <td>{client.address}</td>
                <td>{client.city}</td>
                <td>{client.state}</td>
                <td>{client.number}</td>
                <td>{client.number}</td>
                <td>
                  <button onClick={() => setEditClient(client)}>
                    <i className="bi bi-pencil-square"></i>
                  </button>
                </td>
                <td>
                  <button onClick={() => deleteClient(client.id)}>
                    <i className="bi bi-trash"></i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
}
