import { DevTool } from "@hookform/devtools";
import { useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import axiosAPI from "../../axiosAPI";
import { SelectCity } from "../../components/Selects/SelectCity";
import { SelectState } from "../../components/Selects/SelectState";
import { SelectEmployeesByRoles } from "../Selects/SelectEmployeesByRoles";

export default function ClientsEditForm({ client, setEditClient }) {
  const [selectedUf, setSelectedUf] = useState(client.state);
  const [selectedCity, setSelectedCity] = useState(client.city);

  const { register, handleSubmit, reset, control } = useForm();

  const queryClient = useQueryClient();

  const onSubmitEditUser = (values) => {
    values.client_phone = values.client_phone.replace(/\D/g, "");

    axiosAPI
      .patch(`/clients/${values.client_id}`, values)
      .then((result) => {
        if (result.status == 200) {
          queryClient.invalidateQueries({ queryKey: ["clients", "list"] });
          toast.success("Cliente editado", { position: "bottom-right" });
          setEditClient(false);
        }
      })
      .catch((_) => toast.error("Ops, ocorreu um erro. Tente novamente."));
  };

  return (
    <Form onSubmit={handleSubmit(onSubmitEditUser)}>
      <Form.Group className="mb-3" controlId="form">
        <br />
        <p className="fs-5 fw-bold">Dados do cliente</p>
        <br />
        <div className="d-flex flex-wrap">
          <div className="col-6">
            <FloatingLabel controlId="floatingInput" label="Data de Entrada">
              <Form.Control
                type="date"
                class="form-control"
                name="client_register_date"
                defaultValue={format(new Date(client.created_at), "yyyy-MM-dd")}
                {...register("client_register_date")}
                placeholder="Insira a data de entrada"
                required
              />
            </FloatingLabel>
          </div>
          <div className="col-6">
            <FloatingLabel controlId="floatingInput" label="Horário de Entrada">
              <Form.Control
                type="time"
                class="form-control"
                name="client_register_time"
                defaultValue={format(new Date(client.created_at), "HH:mm")}
                {...register("client_register_time")}
                placeholder="Insira a hora de entrada"
                required
              />
            </FloatingLabel>
          </div>

          <div className="col-6">
            <FloatingLabel label="Nome do cliente">
              <Form.Control
                defaultValue={client.name}
                {...register("client_name")}
                name="client_name"
                type="text"
                className="form-control"
              />
            </FloatingLabel>
          </div>
          <div className="col-6">
            <FloatingLabel label="CPF/CNPJ">
              <Form.Control
                defaultValue={client?.cpf}
                {...register("documento")}
                name="documento"
                type="text"
                className="form-control"
              />
            </FloatingLabel>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
          <SelectEmployeesByRoles
            role={2}
            selected={client.lawyer_id}
            register={register}
            label={"Advogado"}
            option={"Selecione o advogado..."}
          />
        </div>
        <div class="d-flex flex-wrap">
          <div className="col-12 col-sm-12 col-md-12 col-lg-6">
            <FloatingLabel controlId="floatingInput" label="Número de telefone">
              <InputMask
                mask="(99) 9999-9999"
                defaultValue={
                  client.number ? client.number.replace(/\D/g, "") : ""
                }
                {...register("client_phone")}
                className="form-control"
              ></InputMask>
            </FloatingLabel>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-7">
            <FloatingLabel controlId="floatingInput" label="E-mail">
              <Form.Control
                defaultValue={client.email}
                {...register("client_email")}
                name="client_email"
                type="email"
                className="form-control"
              />
            </FloatingLabel>
          </div>
        </div>
        <div class="d-flex flex-wrap">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
            <FloatingLabel controlId="floatingInput" label="Endereço">
              <Form.Control
                defaultValue={client.address}
                {...register("client_address")}
                name="client_address"
                type="text"
                className="form-control"
              />
            </FloatingLabel>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-6">
            <SelectState
              onChange={setSelectedUf}
              selected={selectedUf}
              register={register}
            />
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-6">
            <SelectCity
              uf={selectedUf}
              register={register}
              selected={selectedCity}
            />
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
            <Form.Control
              defaultValue={client.observation}
              {...register("client_observation")}
              name="client_observation"
              as="textarea"
              placeholder="Observações gerais"
              style={{ height: "100px" }}
            />
          </div>

          <Form.Control
            type="hidden"
            value={client.id}
            {...register("client_id")}
            name="client_id"
          />
          <Form.Control
            type="hidden"
            value={client.address_id}
            {...register("client_address_id")}
            name="client_address_id"
          />
          <Form.Control
            type="hidden"
            value={client.phone_id}
            {...register("client_phone_id")}
            name="client_phone_id"
          />

          <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-4">
            <div className="container d-flex align-items-center justify-content-between rowBtn d-flex flex-wrap">
              <div className="d-flex align-items-center justify-content-start col-12 col-sm-12 col-md-12 col-lg-4">
                <Button
                  className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor "
                  onClick={() => setEditClient(0)}
                  style={{ backgroundColor: "#DAA520" }}
                >
                  Cancelar
                </Button>
              </div>
              <div className="d-flex align-items-center col-12 col-sm-12 col-md-6 col-lg-4">
                <Button
                  className="d-flex justify-content-center shadow-sm fs-6 p-2 rounded-2 fw-normal text-dark borderColor "
                  style={{ backgroundColor: "#DAA520" }}
                  type="submit"
                >
                  Salvar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Form.Group>
      <DevTool control={control} /> {/* set up the dev tool */}
    </Form>
  );
}
